var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-area pa-0"},[_c('loading-modal',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(!_vm.loading && !_vm.ui)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" No matching UI found for annotation task. "),(_vm.assets.length > 0)?_c('div',{staticClass:"pt-2"},[_vm._v(" Check there's a matching UI for assets with: "),_c('ul',[_c('li',[_vm._v(" "+_vm._s(Object.values(_vm.assets[0].media).length)+" media file(s) of types: "+_vm._s(_vm.renderFirstAssetWhenNoUi(_vm.assets[0]))+" ")]),_c('li',[_vm._v(" "+_vm._s(Object.values(_vm.assets[0].attributes).length)+" attributes with keys: "+_vm._s(Object.keys(_vm.assets[0].attributes).join(', ') || 'None')+" ")])])]):_vm._e()]):_vm._e(),(
      !_vm.error &&
        !!_vm.layers &&
        !!_vm.ui &&
        !!_vm.activeTask &&
        !!_vm.assets &&
        !!_vm.annotations &&
        !!_vm.activeAsset &&
        _vm.configurationLoaded &&
        _vm.uiLoaded
    )?_c('div',{staticClass:"fill-area"},[_c('film-strip-component',{attrs:{"assets":_vm.assets,"annotations":_vm.annotations,"predictions":_vm.predictions,"activeAsset":_vm.activeAsset,"absoluteLayout":_vm.absoluteLayoutInFilmstrip},on:{"update:activeAsset":_vm.setActiveAsset}}),_c('prefetch-manager',{attrs:{"assets":_vm.assets,"activeAsset":_vm.activeAsset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var fetchMedia = ref.fetchMedia;
return [_c(_vm.ui.component,{ref:"component",tag:"component",attrs:{"task":_vm.activeTask,"assets":_vm.assets,"assetIdToIndex":_vm.assetIdToIndex,"fetchMedia":fetchMedia,"annotations":_vm.annotations,"annotationConfiguration":_vm.uiConfiguration,"annotationUi":_vm.annotationUi,"annotationGuideline":_vm.uiGuideline,"onAnnotateAsset":_vm.saveLabels,"onFinishAnnotating":_vm.navigateToHome,"activeAsset":_vm.activeAsset,"layers":_vm.layers,"status":_vm.status,"registerRouteUpdateListener":_vm.registerRouteUpdateListener},on:{"update:activeAsset":_vm.setActiveAsset}})]}}],null,false,4083253530)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }