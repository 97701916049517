
























































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref
} from '@vue/composition-api';
import { PropType } from 'vue';
import _throttle from 'lodash/throttle';
import _findIndex from 'lodash/findIndex';
import _fromPairs from 'lodash/fromPairs';
import _max from 'lodash/max';
import _min from 'lodash/min';

import { AnnotatedAsset, Asset } from '@/types';
import FilmstripBox from './FilmstripBox.vue';

export default defineComponent({
  name: 'FilmStripComponent',
  components: {
    FilmstripBox
  },
  props: {
    activeAsset: {
      required: true,
      type: Object as PropType<Asset>
    },
    assets: {
      required: true,
      type: Array as PropType<Array<Asset>>
    },
    annotations: {
      required: true,
      type: Array as PropType<Array<AnnotatedAsset>>
    },
    predictions: {
      required: true,
      type: Array as PropType<Array<AnnotatedAsset>>
    },
    absoluteLayout: {
      required: true,
      type: Boolean as PropType<boolean>
    }
  },
  mounted() {
    this.initialize();
  },
  setup(props, { refs }) {
    // Track scroll position so that thumbnails can be loaded only
    // when in view
    const boxes = ref<HTMLDivElement>(null);
    const scrollPositionLeft = ref(0);
    const scrollPositionRight = ref(0);
    function updateScrollPosition() {
      if (!boxes.value) {
        scrollPositionLeft.value = 0;
        scrollPositionRight.value = 0;
        return;
      }
      scrollPositionLeft.value = boxes.value.scrollLeft;
      scrollPositionRight.value =
        boxes.value.scrollLeft + boxes.value.clientWidth;
    }

    const updateScrollPositionThrottled = _throttle(
      updateScrollPosition,
      1000,
      {
        trailing: true,
        leading: true
      }
    );

    onMounted(function() {
      boxes.value = refs.boxes as HTMLDivElement;

      boxes.value.onscroll = function() {
        updateScrollPositionThrottled();
      };
    });

    onUnmounted(() => {
      boxes.value.onscroll = null;
    });
    return { scrollPositionLeft, scrollPositionRight, updateScrollPosition };
  },
  computed: {
    assetIdToAnnotations(): Record<string, AnnotatedAsset> {
      return _fromPairs(this.annotations.map(ann => [ann.asset.id, ann]));
    },
    assetIdToPredictions(): Record<string, AnnotatedAsset> {
      return _fromPairs(this.predictions.map(ann => [ann.asset.id, ann]));
    },
    activeAssetIndex(): number {
      return _findIndex(this.assets, asset => asset.id === this.activeAsset.id);
    }
  },
  data() {
    return {
      expand: false,
      timeout: null as number | null,
      // Time to wait before e.g. scrolling to view
      INITIALIZE_TIMEOUT_MS: 500
    };
  },
  methods: {
    onBoxClick(asset: Asset) {
      this.$emit('update:activeAsset', asset);
    },
    initialize() {
      this.setActiveSlide();
      this.updateScrollPosition();
    },
    isAnnotatedAsset(asset: Asset): boolean {
      return !!this.assetIdToAnnotations[asset.id];
    },
    isPredictedAsset(asset: Asset): boolean {
      return !!this.assetIdToPredictions[asset.id];
    },
    setActiveSlide() {
      const index = this.activeAssetIndex;
      const boxesContainer = this.$refs.boxes as HTMLElement;

      if (!boxesContainer) {
        return;
      }

      const children = boxesContainer.children;

      const box = children[index];

      console.debug(`Scrolling to box`, box);

      box.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    },
    handleSlidePrev() {
      const prevIndex = this.activeAssetIndex - 10;
      const newIndex = _max([0, prevIndex]);
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    handleSlideNext() {
      const nextIndex = this.activeAssetIndex + 10;
      const newIndex = _min([this.assets.length - 1, nextIndex]);
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    initializeWithTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(
        () => this.initialize(),
        this.INITIALIZE_TIMEOUT_MS
      );
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  watch: {
    activeAsset: {
      handler: function() {
        this.initializeWithTimeout();
      }
    },
    expand: {
      handler: function() {
        if (this.expand) {
          this.initializeWithTimeout();
        }
      }
    }
  }
});
