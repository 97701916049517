



import Vue, { PropType } from 'vue';
import _fromPairs from 'lodash/fromPairs';
import _range from 'lodash/range';
import { Asset, FetchMedia, FetchMediaRequest } from '@/types';
import { buildMediaCache } from '@/cache';

const mediaRequestsForAsset = (asset: Asset): FetchMediaRequest[] => {
  const media = asset.media;
  const mediaSlugs = Object.keys(media);

  return mediaSlugs.map(mediaSlug => ({
    assetId: asset.id,
    mediaSlug,
    mediaType: media[mediaSlug].media_type
  }));
};

export default Vue.component('PrefetchManager', {
  props: {
    assets: {
      required: true,
      type: Array as PropType<Asset[]>
    },
    activeAsset: {
      required: true,
      type: Object as PropType<Asset>
    }
  },
  data() {
    const cache = buildMediaCache(this.$api);

    return {
      cache,
      assetIdToIndex: _fromPairs(
        this.assets.map((val, index) => [val.id, index])
      )
    };
  },
  destroyed() {
    this.cache.reset();
  },
  computed: {
    fetchMedia(): FetchMedia {
      return (req: FetchMediaRequest) => this.cache.getOrFetch(req);
    }
  },
  methods: {
    handleActiveAssetChange(asset: Asset) {
      const assetId = asset.id;
      const assetIndex = this.assetIdToIndex[assetId];

      // Which indices to prefetch
      const NEXT_ASSETS_TO_PREFETCH = 1;

      const prefetchedIndices = _range(
        assetIndex + 1,
        assetIndex + 1 + NEXT_ASSETS_TO_PREFETCH
      ).filter(index => index >= 0 && index < this.assets.length);

      const assetsToPrefetch = prefetchedIndices.map(
        index => this.assets[index]
      );

      for (const asset of assetsToPrefetch) {
        for (const mediaRequest of mediaRequestsForAsset(asset)) {
          console.log(
            `Triggering prefetch for asset ${this.assetIdToIndex[asset.id] +
              1}/${this.assets.length}`,
            mediaRequest
          );
          this.$emit('prefetch', mediaRequest);
          this.cache.prefetch(mediaRequest);
        }
      }
    }
  },
  watch: {
    activeAsset: {
      immediate: true,
      handler(val: Asset) {
        this.handleActiveAssetChange(val);
      }
    }
  }
});
