













































































import {
  computed,
  defineComponent,
  Ref,
  toRefs,
  unref
} from '@vue/composition-api';
import _find from 'lodash/find';
import _fromPairs from 'lodash/fromPairs';
import { PropType } from 'vue';

import LoadingModal from '@/components/LoadingModal.vue';
import FilmStripComponent from '@/components/filmstrip/Filmstrip.vue';
import PrefetchManager from '@/prefetch/PrefetchManager.vue';
import { TaskKind } from '@/layers';
import { firstTruthy, or } from '@/utils';
import {
  useGenericTaskAssets,
  useUiConfigurations,
  useUiGuidelines
} from '@/api/use';
import { Asset } from '@/types';
import AnnotationUis from '@/components/annotations/uis';

import {
  useAnnotationLayers,
  useActiveTask,
  useRedirectToAssetIfRequired,
  useSaveLabels,
  useAnnotations,
  useRedirectToUiIfRequired,
  useUiConfiguration,
  useUiGuideline,
  useAnnotationStartTime,
  usePredictions,
  useAnnotationProgressStatus,
  useRouteUpdates,
  useAnnotationUi
} from './functions';
import ImageAnnotator from '../ImageAnnotator';
import ImageClassification from '../ImageClassification';
import VideoAnnotation from '../VideoAnnotation';

export default defineComponent({
  name: 'TaskAnnotateV2',
  components: {
    LoadingModal,
    FilmStripComponent,
    PrefetchManager
  },
  props: {
    taskId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    },
    taskKind: {
      required: true,
      type: String as PropType<TaskKind>
    },
    // Optional query parameters below, the component sets these if missing from query
    assetId: {
      required: false,
      type: String
    },
    uiName: {
      required: false,
      type: String
    }
  },
  setup(props, { root: { $router } }) {
    const { assetId, taskKind, taskId, uiName, projectId } = toRefs(props);

    if (taskKind.value === 'PredictionTask') {
      throw Error(`Cannot annotate a prediction task`);
    }

    const {
      error: errorLoadingLayers,
      loading: loadingLayers,
      layers
    } = useAnnotationLayers(taskKind, taskId);

    const activeTask = useActiveTask(layers);

    const batchId = computed(() => activeTask.value?.batch.id);

    const {
      assets,
      loading: loadingAssets,
      error: errorLoadingAssets
    } = useGenericTaskAssets(taskKind, taskId);

    const assetIdToIndex = computed(() =>
      _fromPairs(
        assets.value.map((a: Asset, idx: number) => {
          return [a.id, idx];
        })
      )
    );

    const activeAsset = computed(() => {
      if (!assetId.value) {
        return null;
      }
      const assetIndex = assetIdToIndex.value[assetId.value];
      return assets.value[assetIndex];
    });

    function setActiveAssetId(assetId: string | Ref<string>) {
      const assetIdUnRef = unref(assetId);
      const route = $router.currentRoute;

      if (assetId !== route.query.asset) {
        $router.replace({
          ...route,
          query: {
            ...route.query,
            asset: assetIdUnRef
          }
        });
      }
    }

    function setActiveAsset(asset: Asset | Ref<Asset>) {
      const assetId = unref(asset)?.id;
      if (assetId) {
        setActiveAssetId(assetId);
      }
    }

    const {
      annotations,
      loading: loadingAnnotations,
      error: errorLoadingAnnotations,
      loaded: annotationsLoaded
    } = useAnnotations(layers);

    const {
      predictions,
      loading: loadingPredictions,
      error: errorLoadingPredictions
    } = usePredictions(layers);

    useRedirectToAssetIfRequired({
      assetId,
      batchId,
      setActiveAssetId,
      annotations,
      annotationsLoaded,
      assets
    });

    useRedirectToUiIfRequired({
      batchId,
      uiName,
      task: activeTask,
      router: $router
    });

    const status = useAnnotationProgressStatus({
      annotations,
      assets
    });

    const ui = computed(() => {
      if (!uiName.value) {
        return undefined;
      }
      const ui = _find(AnnotationUis, ui => ui.name === uiName.value);
      return ui;
    });

    const {
      configurations,
      loading: loadingConfigurations,
      error: errorLoadingConfigurations,
      loaded: configurationsLoaded
    } = useUiConfigurations({ projectId, uiName });

    /**
     * Legacy approach for resolving UI parameters like the list of labels.
     * This is based on querying UI configurations stored in the project,
     * which requires the user to have permission to query configurations.
     */
    const {
      configuration: uiConfiguration,
      loaded: configurationLoaded
    } = useUiConfiguration({
      task: activeTask,
      configurations,
      configurationsLoaded,
      errorLoadingConfigurations
    });

    /**
     * Modern approach to resolve UI parameters like the list of labels.
     * Resolve UI parameters from task object returned by the backend.
     * */
    const { ui: annotationUi, loaded: uiLoaded } = useAnnotationUi({
      task: activeTask,
      uiName,
      configurations,
      configurationsLoaded,
      errorLoadingConfigurations
    });

    const {
      guidelines,
      loading: loadingGuidelines,
      error: errorLoadingGuidelines
    } = useUiGuidelines({ projectId, uiName });

    const { uiGuideline } = useUiGuideline(activeTask, guidelines);

    const { startTime, resetStartTime } = useAnnotationStartTime(assetId);

    const { saveLabels } = useSaveLabels(
      layers,
      annotations,
      startTime,
      resetStartTime
    );

    function navigateToHome() {
      $router.push({
        name: 'Home'
      });
    }

    const loading = or(
      loadingLayers,
      loadingConfigurations,
      loadingGuidelines,
      loadingAnnotations,
      loadingPredictions,
      loadingAssets
    );
    const error = firstTruthy(
      errorLoadingLayers,
      errorLoadingGuidelines,
      errorLoadingAnnotations,
      errorLoadingPredictions,
      errorLoadingAssets
    );

    const absoluteLayoutInFilmstrip = computed(() => {
      return [
        ImageAnnotator.name,
        ImageClassification.name,
        VideoAnnotation.name
      ].includes(uiName.value);
    });

    function renderFirstAssetWhenNoUi(asset: Asset): string {
      return `${Object.values(asset.media)
        .map(
          mediaValue =>
            `"${mediaValue.media_type}" (filename: ${mediaValue.filename})`
        )
        .join(`\n`)}`;
    }

    const { registerRouteUpdateListener, onRouteUpdate } = useRouteUpdates();

    return {
      error,
      loading,
      layers,
      activeTask,
      ui,
      uiConfiguration,
      uiGuideline,
      assets,
      annotations,
      annotationsLoaded,
      assetIdToIndex,
      activeAsset,
      saveLabels,
      navigateToHome,
      setActiveAsset,
      absoluteLayoutInFilmstrip,
      predictions,
      renderFirstAssetWhenNoUi,
      status,
      configurationLoaded,
      registerRouteUpdateListener,
      onRouteUpdate,
      annotationUi,
      uiLoaded
    };
  },
  async beforeRouteUpdate(from, to, next) {
    const canNavigate = await this.onRouteUpdate();

    if (canNavigate) {
      return next();
    }

    return;
  },
  async beforeRouteLeave(from, to, next) {
    const canNavigate = await this.onRouteUpdate();

    if (canNavigate) {
      return next();
    }

    return;
  }
});
