import LRUCache from 'lru-cache';
import objectHash from 'object-hash';
import annotatorApi from '@/api';
import { FetchMediaRequest } from '@/types';

export type Fetch<K, V> = (key: K) => Promise<V>;

export interface RemoteCache<K, V> {
  get(key: K): V | undefined;
  getOrFetch(key: K): Promise<V>;
  prefetch(key: K): Promise<void>;
  reset(): void;
  has(key: K): boolean;
}

export class RemoteCacheImpl<K, V> implements RemoteCache<K, V> {
  private readonly fetch: Fetch<K, V>;
  private readonly cache: LRUCache<string, V>;
  constructor(fetch: Fetch<K, V>, cache: LRUCache<string, V>) {
    this.fetch = fetch;
    this.cache = cache;
  }

  hash(key: K): string {
    return objectHash(key);
  }

  set(key: K, value: V) {
    const hashedKey = this.hash(key);
    this.cache.set(hashedKey, value);
  }

  has(key: K): boolean {
    const hashedKey = this.hash(key);
    return this.cache.has(hashedKey);
  }

  get(key: K): V | undefined {
    const hashedKey = this.hash(key);
    const valueOrUndefined = this.cache.get(hashedKey);
    if (!valueOrUndefined) {
      console.log(`Cache miss`, key);
    } else {
      console.log(`Cache hit`, key);
    }
    return valueOrUndefined;
  }

  async getOrFetch(key: K): Promise<V> {
    const valueOrUndefined = this.get(key);
    if (valueOrUndefined) {
      return valueOrUndefined;
    }
    console.log(`Fetching media`, key);
    const value = await this.fetch(key);
    this.set(key, value);
    return value;
  }

  async prefetch(key: K): Promise<void> {
    if (this.has(key)) {
      console.debug(`Key in cache, skipping prefetch`);
      return;
    }
    console.log(`Prefetching media`, key);
    const value = await this.fetch(key);
    this.set(key, value);
  }

  reset(): void {
    this.cache.clear();
  }
}

export function buildMediaCache(
  api: typeof annotatorApi
): RemoteCache<FetchMediaRequest, ArrayBuffer> {
  const maxObjects = 10;
  const maxCacheSizeMb = 20;
  const options = {
    max: maxObjects,
    maxSize: maxCacheSizeMb * 1024 * 1024,
    sizeCalculation: function(value: ArrayBuffer) {
      return value.byteLength; // Use size in bytes for array buffers to calculate size
    }
  };

  const cache = new LRUCache<string, ArrayBuffer>(options);

  const fetch = (key: FetchMediaRequest) => api.assets.getMedia(key);
  return new RemoteCacheImpl(fetch, cache);
}
