







import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
  toRefs,
  watchEffect
} from '@vue/composition-api';
import _sortBy from 'lodash/sortBy';
import { PropType } from 'vue';
import api from '@/api';
import { Asset, AssetMedia } from '@/types';
import { arrayBufferToBase64 } from '@/api/utils';
import { whenever } from '@vueuse/core';

function isImage(media: AssetMedia): boolean {
  return media.media_type.startsWith('image/');
}

/**
 * Read thumbnail for asset if exists.
 * If there are multiple images, pick the first one.
 */
function useThumbnail({
  asset,
  load,
  onLoad
}: {
  asset: Ref<Asset>;
  load: Ref<boolean>;
  onLoad: () => void;
}) {
  // Read media from asset
  // If has one image, show its thumbnail
  const loading = ref(false);
  const error = ref<Error>(null);
  const thumbnail = ref<string>(null);

  async function fetchThumbnail(): Promise<string | undefined> {
    const media = asset.value?.media;
    if (!media) {
      return;
    }

    const slugImagePairs = _sortBy(
      Object.entries(media).filter(([_, media]) => isImage(media)),
      function(pair: [string, AssetMedia]) {
        return pair[0];
      }
    );

    if (slugImagePairs.length === 0) {
      return;
    }

    const mediaSlug = slugImagePairs[0][0];
    const image = slugImagePairs[0][1];

    const thumbnailUrl = image.thumbnail_url;

    if (!thumbnailUrl) {
      return;
    }

    const assetId = asset.value.id;
    const mediaType = image.media_type;

    const buffer = await api.assets.getMediaThumbnail({
      assetId,
      mediaSlug,
      mediaType
    });

    const dataBase64 = arrayBufferToBase64(buffer);
    return `data:${mediaType};base64, ${dataBase64}`;
  }

  async function fetch() {
    if (thumbnail.value || !load.value || loading.value) {
      return;
    }
    onLoad();
    loading.value = true;
    error.value = null;
    try {
      const dataBase64 = await fetchThumbnail();
      thumbnail.value = dataBase64;
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  whenever(load, fetch, { immediate: true });

  return { thumbnail, loading, error };
}

export default defineComponent({
  props: {
    asset: {
      required: true,
      type: Object as PropType<Asset>
    },
    index: {
      required: true,
      type: Number
    },
    selected: {
      required: true,
      type: Boolean
    },
    isAnnotated: {
      required: true,
      type: Boolean
    },
    isPredicted: {
      required: true,
      type: Boolean
    },
    scrollPositionLeft: {
      required: true,
      type: Number
    },
    scrollPositionRight: {
      required: true,
      type: Number
    }
  },
  setup(props, { emit, refs }) {
    const {
      asset,
      isAnnotated,
      isPredicted,
      selected,
      scrollPositionLeft,
      scrollPositionRight,
      index
    } = toRefs(props);

    const box = ref<HTMLDivElement>(null);

    function onClick() {
      emit('onClick', asset.value);
    }

    onMounted(function() {
      box.value = refs.box as HTMLDivElement;
    });

    /** Load thumbnails only when the element is in view */
    const load = computed(() => {
      // Register tracked variables
      const posLeft = scrollPositionLeft.value;
      const posRight = scrollPositionRight.value;
      if (!box.value) {
        return false;
      }
      const ownPosition = box.value.offsetLeft;
      const boxWidth = box.value.clientWidth;

      // Own position can be zero if not yet initialized
      // Only allow loading in this case if the first image
      if (ownPosition < 1) {
        return index.value === 0;
      }

      return posLeft <= ownPosition + boxWidth && ownPosition <= posRight;
    });

    const { thumbnail } = useThumbnail({
      asset,
      load,
      onLoad: function() {
        console.debug(`Loading thumbnail for image index: ${index.value + 1}`);
      }
    });

    const buttonClass = computed(() => ({
      button: true,
      selected: selected.value,
      annotated: isAnnotated.value,
      predicted: isPredicted.value && !isAnnotated.value,
      'filmstrip-box': true,
      'pa-2': true,
      'mx-2': true,
      grow: true
    }));

    return {
      onClick,
      buttonClass,
      thumbnail
    };
  }
});
