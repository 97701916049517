import * as z from 'zod';
import _isEmpty from 'lodash/isEmpty';
import validateColor from 'validate-color';

export const DEFAULT_COLOR = '#ff1a1a';

export interface VideoObjectCategory {
  name: string;
  color: string;
}

export const DEFAULT_CONFIG: { labels: Array<VideoObjectCategory | string> } = {
  labels: [
    {
      name: 'Car',
      color: '#ff1a1a'
    },
    {
      name: 'Bus',
      color: '#1aff1a'
    },
    {
      name: 'Sign',
      color: '#1a1aff'
    },
    {
      name: 'Pedestrian',
      color: '#ffff1a'
    }
  ]
};

export type VideoAnnotationParametersDefaultType = typeof DEFAULT_CONFIG;

const ColorNameSchema = z
  .string()
  .refine((name: string) => validateColor(name), {
    message: 'Invalid CSS color'
  });

const LabelObjectSchema = z.object({
  name: z.string(),
  color: ColorNameSchema
});

export const VideoAnnotationParametersSchema = z.object({
  labels: z.union([
    z.array(LabelObjectSchema).nonempty(),
    z.array(z.string()).nonempty()
  ])
});

export type VideoAnnotationParameters = z.infer<
  typeof VideoAnnotationParametersSchema
>;

const checkParameters = (params: any): params is VideoAnnotationParameters => {
  try {
    return !!VideoAnnotationParametersSchema.parse(params);
  } catch (err) {
    console.error(`Invalid parameters`, params);
    return false;
  }
};

export const resolveParameters = (params: any): VideoAnnotationParameters => {
  if (!_isEmpty(params) && !checkParameters(params)) {
    console.warn('Invalid parameters obtained, proceed with caution');
  }

  return { ...DEFAULT_CONFIG, ...params };
};
