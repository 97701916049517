import { Asset } from '@/types';
import { AnnotationUI } from '../annotations/ui';
/** Do not directly import anything that imports paper.js or video.js as they're big bundles. */
import {
  DEFAULT_CONFIG,
  VideoAnnotationParametersSchema,
  resolveParameters
} from './config';

const controller = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "video-annotation" */
    './VideoAnnotation.vue'
  );

const canLabelAsset = (asset: Asset) => {
  const media = asset.media;
  const mediaTypes = Object.values(media)
    .map(val => val.media_type)
    .filter(mediaType => mediaType.startsWith('video/'));
  return mediaTypes.length === 1;
};

const VideoAnnotationUI: AnnotationUI = {
  component: controller,
  name: 'VideoAnnotation',
  canLabelAsset,
  defaultConfig: DEFAULT_CONFIG,
  resolveParameters,
  configSchema: VideoAnnotationParametersSchema,
  supportsReview: true
};

export default VideoAnnotationUI;
